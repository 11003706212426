// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-template-index-jsx": () => import("./../src/templates/BlogTemplate/index.jsx" /* webpackChunkName: "component---src-templates-blog-template-index-jsx" */),
  "component---src-templates-talk-template-index-jsx": () => import("./../src/templates/TalkTemplate/index.jsx" /* webpackChunkName: "component---src-templates-talk-template-index-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-talks-js": () => import("./../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-uses-js": () => import("./../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */)
}

